import { notUndefined } from '@thinkalpha/common/util/notNullOrUndefined.js';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import type { WidgetType } from 'src/contracts/workspace';
import type { RootState } from 'src/store';
import type { OnTabChannelChangeEvent, ResetChannelDataAction } from 'src/store/actions/widgetAndChannel';
import { type UserDoubleClickedAccountFromTableAction } from 'src/store/actions/widgets/accounts';
import { updateExecutionsAccount, updateExecutionsSymbolFilter } from 'src/store/actions/widgets/executions';
import { type UserSelectedNewAlphaLensSymbolAction, updateAlphaLensSymbol } from 'src/store/actions/widgets/lens';
import {
    updateLocatesAccount,
    updateLocatesSymbol,
    type UpdateLocatesSymbolAction,
} from 'src/store/actions/widgets/locates';
import { updateMessagesAccount, updateMessagesSymbolFilter } from 'src/store/actions/widgets/messages';
import { userSetNewsStrategy } from 'src/store/actions/widgets/news';
import { updateOrderBlotterAccount, updateOrderBlotterSymbol } from 'src/store/actions/widgets/orderBlotter';
import {
    updateOrderEntryAccount,
    updateOrderEntrySymbol,
    type UpdateOrderEntrySymbolAction,
} from 'src/store/actions/widgets/orderEntry';
import {
    updateOrdersAccount,
    updateOrdersSymbolFilter,
    type UserDoubleClickedOrderFromTableAction,
} from 'src/store/actions/widgets/orders';
import {
    updatePositionsAccount,
    updatePositionsSymbolFilter,
    type UserDoubleClickedPositionFromTableAction,
} from 'src/store/actions/widgets/positions';
import { setResultsStrategy, type UserDoubleClickedSymbolFromTableAction } from 'src/store/actions/widgets/results';
import { updateWidgetStrategy, type UserRanChangesAction } from 'src/store/actions/widgets/searchAlpha';
import { type UpdateTimeSeriesSymbolAction, updateTimeSeriesSymbol } from 'src/store/actions/widgets/timeSeries';
import type { TabState } from 'src/store/reducers/tab';
import type { WidgetTabViewModel } from 'src/store/types';

const THE_MATRIX_OF_LINKING: Record</* from */ WidgetType, /* to */ WidgetType[]> = {
    strategy: [
        'alpha-lens',
        'executions',
        'locates',
        'messages',
        'order-blotter',
        'order-entry',
        'orders',
        'positions',
        'simple-order-entry',
        'time-series',
    ],
    watchlist: [
        'alpha-lens',
        'executions',
        'locates',
        'messages',
        'order-blotter',
        'order-entry',
        'orders',
        'positions',
        'simple-order-entry',
        'time-series',
    ],
    'if-then-builder': ['strategy'],
    'order-blotter': [
        'time-series',
        'order-entry',
        'messages',
        'positions',
        'executions',
        'orders',
        'alpha-lens',
        'simple-order-entry',
    ],
    searchalpha: [
        /** symbol from grid */
        'alpha-lens',
        'executions',
        'locates',
        'messages',
        'order-blotter',
        'order-entry',
        'orders',
        'positions',
        'simple-order-entry',
        'time-series',
    ],
    'order-entry': [
        'alpha-lens',
        'time-series',
        'simple-order-entry',
        'locates',
        'messages',
        'order-blotter',
        'positions',
        'executions',
        'orders',
    ],
    'simple-order-entry': [
        'alpha-lens',
        'time-series',
        'order-entry',
        'locates',
        'messages',
        'order-blotter',
        'positions',
        'executions',
        'orders',
    ],
    messages: ['alpha-lens', 'order-blotter', 'positions', 'executions', 'orders', 'order-entry', 'simple-order-entry'],
    positions: [
        'time-series',
        'order-blotter',
        'messages',
        'order-entry',
        'simple-order-entry',
        'executions',
        'orders',
        'locates',
        'alpha-lens',
    ],
    'aggregated-positions': [],
    accounts: [
        'messages',
        'order-blotter',
        'positions',
        'order-entry',
        'executions',
        'orders',
        'locates',
        'simple-order-entry',
    ],
    orders: [
        'order-entry',
        'messages',
        'time-series',
        'executions',
        'order-blotter',
        'alpha-lens',
        'simple-order-entry',
    ],
    table: [],
    executions: ['alpha-lens', 'messages', 'order-entry', 'simple-order-entry', 'time-series'],
    locates: ['alpha-lens', 'order-entry', 'time-series', 'simple-order-entry'],
    'time-series': ['alpha-lens', 'time-series', 'order-entry', 'simple-order-entry'],
    'alpha-lens': ['alpha-lens', 'time-series', 'order-entry', 'simple-order-entry'],
    'news-and-events': ['alpha-lens', 'time-series', 'events'],
    events: ['alpha-lens', 'time-series', 'news-and-events'],
    scanner: [
        /** symbol from grid */
        'alpha-lens',
        'executions',
        'locates',
        'messages',
        'order-blotter',
        'order-entry',
        'orders',
        'positions',
        'simple-order-entry',
        'time-series',
    ],
    screener: [
        /** symbol from grid */
        'alpha-lens',
        'executions',
        'locates',
        'messages',
        'order-blotter',
        'order-entry',
        'orders',
        'positions',
        'simple-order-entry',
        'time-series',
    ],
    filings: ['alpha-lens', 'time-series'],
};

function* getTabsThatCanBeCommunicateWith(originatingTabId: string) {
    const allWidgetsInWorkspace: TabState['byId'] = yield select((state: RootState) => state.tab.byId);
    const allTabsInDashboard = Object.values(allWidgetsInWorkspace);
    const originatingTab = allTabsInDashboard.find((tab) => tab.id === originatingTabId);

    if (!originatingTab?.channelId) return [];

    const originatingChannelId = originatingTab.channelId;
    const otherTabsInChannel = allTabsInDashboard.filter((tab) => {
        if (tab.channelId !== originatingChannelId) return false;
        if (tab.id === originatingTabId) return false;

        // ToDo:
        // So! Here's where we will be able to pull the current tab's rules of who they can publish to
        // it would look something like this:
        // if (originatingTab.channelSubscriptionSettings?.dontPublishTo?.includes(tab.widget.type)) return false;
        // if (tab?.channelSubscriptionSettings?.dontListenTo?.includes(originatingTab.widget.type)) return false;
        // see projects/web/src/store/types/index.ts WidgetTabViewModel.channelSubscriptionSettings

        // The following lines will be replaced in the future with the above lines:
        return THE_MATRIX_OF_LINKING[originatingTab.widget.type].includes(tab.widget.type);
    });

    return otherTabsInChannel;
}

function* handleUserSelectedAccount(action: UserDoubleClickedAccountFromTableAction) {
    const widgetsThatShouldReceiveUpdates = yield* getTabsThatCanBeCommunicateWith(action.tabId);
    if (!widgetsThatShouldReceiveUpdates.length) return;

    const widgetUpdates = widgetsThatShouldReceiveUpdates
        .map((x) => {
            switch (x.widget.type) {
                case 'order-blotter':
                    return updateOrderBlotterAccount(x.id, action.accountId);
                case 'messages':
                    return updateMessagesAccount(x.id, action.accountId);
                case 'positions':
                    return updatePositionsAccount(x.id, action.accountId);
                case 'order-entry':
                case 'simple-order-entry':
                    return updateOrderEntryAccount(x.id, action.accountId);
                case 'executions':
                    return updateExecutionsAccount(x.id, action.accountId);
                case 'orders':
                    return updateOrdersAccount(x.id, action.accountId);
                case 'locates':
                    return updateLocatesAccount(x.id, action.accountId);
                default:
                    return undefined;
            }
        })
        .filter(notUndefined);
    if (widgetUpdates.length === 0) return;

    yield all(widgetUpdates.map((x) => put(x)));
}

function* handleUserSelectedSymbol(action: UserDoubleClickedSymbolFromTableAction) {
    const widgetsThatShouldReceiveUpdates = yield* getTabsThatCanBeCommunicateWith(action.tabId);
    if (!widgetsThatShouldReceiveUpdates.length) return;

    const widgetUpdates = widgetsThatShouldReceiveUpdates
        .map((x) => {
            switch (x.widget.type) {
                case 'alpha-lens':
                    return updateAlphaLensSymbol(x.id, action.symbol);
                case 'executions':
                    return updateExecutionsSymbolFilter(x.id, action.symbol);
                case 'time-series':
                    return updateTimeSeriesSymbol(x.id, action.symbol);
                case 'messages':
                    return updateMessagesSymbolFilter(x.id, action.symbol);
                case 'positions':
                    return updatePositionsSymbolFilter(x.id, action.symbol);
                case 'order-entry':
                case 'simple-order-entry':
                    return updateOrderEntrySymbol(x.id, action.symbol);
                case 'orders':
                    return updateOrdersSymbolFilter(x.id, action.symbol);
                case 'locates':
                    return updateLocatesSymbol(x.id, action.symbol);
                case 'order-blotter':
                    return updateOrderBlotterSymbol(x.id, action.symbol);
                default:
                    return undefined;
            }
        })
        .filter(notUndefined);
    if (widgetUpdates.length === 0) return;

    yield all(widgetUpdates.map((x) => put(x)));
}

function* handleUserSelectedNewAlphaLensSymbol(action: UserSelectedNewAlphaLensSymbolAction) {
    // Base case for original widget
    yield put(updateAlphaLensSymbol(action.tabId, action.symbol));

    const widgetsThatShouldReceiveUpdates = yield* getTabsThatCanBeCommunicateWith(action.tabId);
    if (!widgetsThatShouldReceiveUpdates.length) return;

    const widgetUpdates = widgetsThatShouldReceiveUpdates
        .map((x) => {
            if (x.widget.type === 'alpha-lens') {
                return updateAlphaLensSymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'time-series') {
                return updateTimeSeriesSymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'order-entry' || x.widget.type === 'simple-order-entry') {
                return updateOrderEntrySymbol(x.id, action.symbol);
            }
            return undefined;
        })
        .filter(notUndefined);

    if (widgetUpdates.length === 0) return;

    yield all(widgetUpdates.map((x) => put(x)));
}

function* handleTimeSeriesNewSymbolSelected(action: UpdateTimeSeriesSymbolAction) {
    // Base case for original widget
    yield put(updateTimeSeriesSymbol(action.tabId, action.symbol));

    const widgetsThatShouldReceiveUpdates = yield* getTabsThatCanBeCommunicateWith(action.tabId);
    if (!widgetsThatShouldReceiveUpdates.length) return;

    const widgetUpdates = widgetsThatShouldReceiveUpdates
        .map((x) => {
            if (x.widget.type === 'alpha-lens') {
                return updateAlphaLensSymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'time-series') {
                return updateTimeSeriesSymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'order-entry' || x.widget.type === 'simple-order-entry') {
                return updateOrderEntrySymbol(x.id, action.symbol);
            }

            return undefined;
        })
        .filter(notUndefined);

    if (widgetUpdates.length === 0) return;

    yield all(widgetUpdates.map((x) => put(x)));
}

function* handleOrderEntryChangedSymbol(action: UpdateOrderEntrySymbolAction) {
    // Base case for original widget
    yield put(updateOrderEntrySymbol(action.tabId, action.symbol));

    const widgetsThatShouldReceiveUpdates = yield* getTabsThatCanBeCommunicateWith(action.tabId);
    if (!widgetsThatShouldReceiveUpdates.length) return;

    const widgetUpdates = widgetsThatShouldReceiveUpdates
        .map((x) => {
            if (x.widget.type === 'alpha-lens') {
                return updateAlphaLensSymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'time-series') {
                return updateTimeSeriesSymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'order-entry' || x.widget.type === 'simple-order-entry') {
                return updateOrderEntrySymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'locates') {
                return updateLocatesSymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'order-blotter') {
                return updateOrderBlotterSymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'messages') {
                return updateMessagesSymbolFilter(x.id, action.symbol);
            }
            if (x.widget.type === 'positions') {
                return updatePositionsSymbolFilter(x.id, action.symbol);
            }
            if (x.widget.type === 'executions') {
                return updateExecutionsSymbolFilter(x.id, action.symbol);
            }
            if (x.widget.type === 'orders') {
                return updateOrdersSymbolFilter(x.id, action.symbol);
            }
            return undefined;
        })
        .filter(notUndefined);

    if (widgetUpdates.length === 0) return;
    yield all(widgetUpdates.map((x) => put(x)));
}

function* handleUserChangedLocatesSymbol(action: UpdateLocatesSymbolAction) {
    // Base case for original widget
    yield put(updateLocatesSymbol(action.tabId, action.symbol));

    const widgetsThatShouldReceiveUpdates = yield* getTabsThatCanBeCommunicateWith(action.tabId);
    if (!widgetsThatShouldReceiveUpdates.length) return;

    const widgetUpdates = widgetsThatShouldReceiveUpdates
        .map((x) => {
            if (x.widget.type === 'alpha-lens') {
                return updateAlphaLensSymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'time-series') {
                return updateTimeSeriesSymbol(x.id, action.symbol);
            }
            if (x.widget.type === 'order-entry' || x.widget.type === 'simple-order-entry') {
                return updateOrderEntrySymbol(x.id, action.symbol);
            }

            return undefined;
        })
        .filter(notUndefined);

    if (widgetUpdates.length === 0) return;
    yield all(widgetUpdates.map((x) => put(x)));
}

function* handleResetChannelInfo(action: OnTabChannelChangeEvent | ResetChannelDataAction) {
    const currentWidget: WidgetTabViewModel = yield select((state: RootState) => state.tab.byId[action.tabId]);
    if (!currentWidget) return;

    switch (currentWidget.widget.type) {
        case 'messages': {
            yield put(updateMessagesSymbolFilter(action.tabId, ''));
            break;
        }
        case 'positions': {
            yield put(updatePositionsSymbolFilter(action.tabId, ''));
            break;
        }

        case 'executions': {
            yield put(updateExecutionsSymbolFilter(action.tabId, ''));
            break;
        }
        case 'orders': {
            yield put(updateOrdersSymbolFilter(action.tabId, ''));
            break;
        }
    }
}

function* handleRunChanges(action: UserRanChangesAction) {
    yield put(updateWidgetStrategy(action.tabId, action.strategy));

    const widgetsThatShouldReceiveUpdates = yield* getTabsThatCanBeCommunicateWith(action.tabId);
    if (!widgetsThatShouldReceiveUpdates.length) return;

    const widgetUpdates = widgetsThatShouldReceiveUpdates
        .map((x) => {
            if (x.widget.type === 'strategy') {
                return setResultsStrategy(x.id, action.strategy);
            } else if (x.widget.type === 'news-and-events') {
                return userSetNewsStrategy(x.id, action.strategy.id ?? null);
            }

            return undefined;
        })
        .filter(notUndefined);

    if (widgetUpdates.length === 0) return;
    yield all(widgetUpdates.map((x) => put(x)));
}

function* handleUserSelectedPosition(action: UserDoubleClickedPositionFromTableAction) {
    // Base case for current widget
    if (action.accountId) {
        yield put(updatePositionsAccount(action.tabId, action.accountId));
    }

    const widgetsThatShouldReceiveUpdates = yield* getTabsThatCanBeCommunicateWith(action.tabId);
    if (!widgetsThatShouldReceiveUpdates.length) return;

    const { symbol } = action;

    const widgetUpdates = widgetsThatShouldReceiveUpdates
        .map((x) => {
            if (symbol) {
                if (x.widget.type === 'time-series') {
                    return updateTimeSeriesSymbol(x.id, symbol);
                }
                if (x.widget.type === 'order-blotter') {
                    return updateOrderBlotterSymbol(x.id, symbol);
                }
                if (x.widget.type === 'order-entry') {
                    return updateOrderEntrySymbol(x.id, symbol);
                }
                if (x.widget.type === 'messages') {
                    return updateMessagesSymbolFilter(x.id, symbol);
                }
                if (x.widget.type === 'executions') {
                    return updateExecutionsSymbolFilter(x.id, symbol);
                }
                if (x.widget.type === 'orders') {
                    return updateOrdersSymbolFilter(x.id, symbol);
                }
                if (x.widget.type === 'locates') {
                    return updateLocatesSymbol(x.id, symbol);
                }
                if (x.widget.type === 'alpha-lens') {
                    return updateAlphaLensSymbol(x.id, symbol);
                }
            }

            return undefined;
        })
        .filter(notUndefined);
    if (widgetUpdates.length === 0) return;
    yield all(widgetUpdates.map((x) => put(x)));
}
function* handleUserSelectedOrder(action: UserDoubleClickedOrderFromTableAction) {
    const widgetsThatShouldReceiveUpdates = yield* getTabsThatCanBeCommunicateWith(action.tabId);
    if (!widgetsThatShouldReceiveUpdates.length) return;

    const { symbol } = action;

    const widgetUpdates = widgetsThatShouldReceiveUpdates
        .map((x) => {
            if (symbol) {
                if (x.widget.type === 'alpha-lens') {
                    return updateAlphaLensSymbol(x.id, symbol);
                }
                if (x.widget.type === 'order-blotter') {
                    return updateOrderBlotterSymbol(x.id, symbol);
                }
                if (x.widget.type === 'order-entry') {
                    return updateOrderEntrySymbol(x.id, symbol);
                }
                if (x.widget.type === 'messages') {
                    return updateMessagesSymbolFilter(x.id, symbol);
                }
                if (x.widget.type === 'executions') {
                    return updateExecutionsSymbolFilter(x.id, symbol);
                }
                if (x.widget.type === 'time-series') {
                    return updateTimeSeriesSymbol(x.id, symbol);
                }
            }

            return undefined;
        })
        .filter(notUndefined);
    if (widgetUpdates.length === 0) return;
    yield all(widgetUpdates.map((x) => put(x)));
}

export function* linkingSagas() {
    yield all([
        takeEvery(['onTabChannelChange', 'resetChannelData'], handleResetChannelInfo),
        takeEvery('userSelectedNewAlphaLensSymbol', handleUserSelectedNewAlphaLensSymbol),
        takeEvery('userDoubleClickedSymbolFromTable', handleUserSelectedSymbol),
        takeEvery('userDoubleClickedAccountFromTable', handleUserSelectedAccount),
        takeEvery('changeTimeSeriesSymbol', handleTimeSeriesNewSymbolSelected),
        takeEvery('userSelectedNewOrderEntrySymbol', handleOrderEntryChangedSymbol),
        takeEvery('userSelectedNewLocatesSymbol', handleUserChangedLocatesSymbol),
        takeEvery('userDoubleClickedPositionFromTable', handleUserSelectedPosition),
        takeEvery('userDoubleClickedOrderFromTable', handleUserSelectedOrder),
        takeEvery('userRanChanges', handleRunChanges),
    ]);
}
