import { type ScreenerPlanDTO } from '../dtos/ScreenerPlanDTO';
import { type Stage } from '@thinkalpha/platform-ws-client/contracts/table.js';
import { getSnapshot } from 'src/api/getTableSnapshot';
import { IdeaDTO } from 'src/dtos/Idea';
import type { ThinkAlphaQueryClient } from 'src/lib/config/query-client';
import { createTable } from 'src/lib/table';
import { getWholeSlugMapQuery } from 'src/queries/slugMap';

export const getScreenerRowCount = async (
    queryClient: ThinkAlphaQueryClient,
    signal: AbortSignal,
    screener?: ScreenerPlanDTO,
): Promise<number> => {
    const slugMap = await queryClient.fetchQuery(getWholeSlugMapQuery());

    if (signal.aborted || !slugMap?.symbol) {
        return 0;
    }

    const tempIdea = screener && new IdeaDTO({ name: 'idc', plan: screener });

    const tcr = await queryClient.fetchUserQuery({
        queryKey: ['screener-rows-count-tcr', screener ?? 'total'],
        queryFn: async () => {
            let stage: Stage = {
                type: 'output',
                columns: [{ id: 'symbol', type: 'indicator', indicator: slugMap.symbol }],
                inputStage: {
                    type: 'row-source',
                    rowKey: 'ticker',
                    universe: undefined,
                },
            };
            if (tempIdea) {
                const ideaFilterStagePart = await tempIdea.toFilterStage(queryClient);
                if (ideaFilterStagePart) {
                    stage = {
                        ...ideaFilterStagePart,
                        inputStage: stage,
                    };
                }
            }
            return createTable({ leafStage: stage });
        },
        staleTime: 0,
        gcTime: 0,
    });

    if (signal.aborted || !tcr) {
        return 0;
    }

    return queryClient.fetchQuery({
        queryKey: ['screener-rows-count-snapshot', tcr.key, tcr.tableCookie],
        queryFn: async () => {
            const snapshot = await getSnapshot(tcr.key, tcr.tableCookie, 0, 0);
            return snapshot.rowsCount;
        },
    });
};
