import {
    getDefaultAlphaLensWidget,
    getDefaultNewsWidget,
    getDefaultStrategyWidget,
    getDefaultTimeSeriesWidget,
    getDefaultWatchlistWidget,
} from './defaults';
import type { ConcreteUniverse } from '@thinkalpha/platform-ws-client/contracts/universe.js';
import { call, getContext, select } from 'redux-saga/effects';
import type {
    AccountsWidgetModel,
    AggregatedPositionsWidgetModel,
    LocatesWidgetModel,
    OrderBlotterWidgetModel,
    OrderEntryWidgetModel,
    TableWidgetModel,
    WidgetCreationInfo,
    SimpleOrderEntryWidgetModel,
} from 'src/contracts/workspace';
import { UnreachableCaseError } from 'src/lib/util/unreachableCaseError';
import { getDefaultUniverseQuery } from 'src/queries/universes';
import { defaultQuantityStep } from 'src/routes/widgets/LocatesWidget/formatting';
import { type RootState } from 'src/store';
import {
    createDefaultScannerWidget,
    createDefaultScreenerWidget,
    createDefaultSearchAlphaWidget,
} from 'src/store/defaults/widget';
import type {
    EventsWidgetViewModel,
    ExecutionsWidgetViewModel,
    FilingsWidgetViewModel,
    IfThenBuilderWidgetViewModel,
    MessagesWidgetViewModel,
    OrdersWidgetViewModel,
    PositionsWidgetViewModel,
    SagaContext,
    ScannerWidgetViewModel,
    SearchAlphaWidgetViewModel,
    WidgetTabViewModel,
    WidgetViewModel,
} from 'src/store/types';

export function* getWidgetFromCreationInfo(creationInfo: WidgetCreationInfo): Generator<any, WidgetViewModel, any> {
    const channelId = 'channelId' in creationInfo ? creationInfo.channelId : null;

    switch (creationInfo.creationType) {
        case 'cloned': {
            const widgetTab: WidgetTabViewModel = yield select(
                (store: RootState) => store.tab.byId[creationInfo.baseWidgetTabId],
            );

            return widgetTab.widget;
        }

        case 'new-alpha-lens': {
            const widget = yield* getDefaultAlphaLensWidget(creationInfo.defaultSymbol);
            return widget;
        }

        case 'new-time-series': {
            const widget = yield* getDefaultTimeSeriesWidget(creationInfo.defaultSymbol);
            return widget;
        }

        case 'new-searchalpha': {
            const container: NonNullable<SagaContext['container']> = yield getContext('container');
            const queryClient = container.get('QueryClient');
            const defaultUniverse: ConcreteUniverse | undefined = yield call(() =>
                queryClient.fetchUserQuery(getDefaultUniverseQuery()),
            );
            const widget: SearchAlphaWidgetViewModel = createDefaultSearchAlphaWidget({
                universeId: defaultUniverse?.id ?? null,
                idea: creationInfo.idea,
                channelId: creationInfo.channelId,
            });

            return widget;
        }

        case 'new-strategy': {
            const widget = yield* getDefaultStrategyWidget(creationInfo.strategies, creationInfo.defaultSymbol);

            return widget;
        }

        case 'from-widget-default': {
            const widgetType = creationInfo.widgetType;
            switch (widgetType) {
                case 'alpha-lens': {
                    return yield* getDefaultAlphaLensWidget();
                }
                case 'time-series': {
                    return yield* getDefaultTimeSeriesWidget();
                }
                case 'strategy': {
                    return yield* getDefaultStrategyWidget([]);
                }
                case 'watchlist': {
                    return yield* getDefaultWatchlistWidget();
                }
                case 'news-and-events': {
                    return yield* getDefaultNewsWidget();
                }
                case 'events': {
                    const widget: EventsWidgetViewModel = {
                        type: 'events',
                        channelId: null,
                        universe: null,
                        symbolFilter: '',
                        gotoDate: null,
                    };

                    return widget;
                }
                case 'scanner': {
                    const container: NonNullable<SagaContext['container']> = yield getContext('container');
                    const queryClient = container.get('QueryClient');
                    const defaultUniverse: ConcreteUniverse | undefined = yield call(() =>
                        queryClient.fetchUserQuery(getDefaultUniverseQuery()),
                    );
                    const widget: ScannerWidgetViewModel = createDefaultScannerWidget({
                        universeId: defaultUniverse?.id ?? null,
                    });

                    return widget;
                }
                case 'screener': {
                    return createDefaultScreenerWidget();
                }
                case 'filings': {
                    const widget: FilingsWidgetViewModel = {
                        type: 'filings',
                        channelId: null,
                        universe: null,
                        symbolFilter: '',
                        gotoDate: null,
                        filingTypes: null,
                    };

                    return widget;
                }
                case 'searchalpha': {
                    const container: NonNullable<SagaContext['container']> = yield getContext('container');
                    const queryClient = container.get('QueryClient');
                    const defaultUniverse: ConcreteUniverse | undefined = yield call(() =>
                        queryClient.fetchUserQuery(getDefaultUniverseQuery()),
                    );
                    const widget: SearchAlphaWidgetViewModel = createDefaultSearchAlphaWidget({
                        universeId: defaultUniverse?.id ?? null,
                    });

                    return widget;
                }
                case 'order-blotter': {
                    const widget: OrderBlotterWidgetModel = {
                        type: 'order-blotter',
                        channelId,
                        accountId: null,
                        statusFilter: null,
                        viewData: null,
                        formattingSettings: null,
                        tickerFilter: null,
                    };

                    return widget;
                }

                case 'accounts': {
                    const widget: AccountsWidgetModel = {
                        type: 'accounts',
                        channelId,
                        columnTemplate: null,
                        platforms: null,
                        accountIds: null,
                        userIds: null,
                        filter: null,
                    };

                    return widget;
                }
                case 'aggregated-positions': {
                    const widget: AggregatedPositionsWidgetModel = {
                        type: 'aggregated-positions',
                        channelId,
                        columnTemplate: null,
                        platforms: null,
                        accountIds: null,
                        userIds: null,
                        aggregateBy: 'user',
                        filter: null,
                    };

                    return widget;
                }
                case 'if-then-builder': {
                    const widget: IfThenBuilderWidgetViewModel = {
                        type: 'if-then-builder',
                        channelId,
                        strategy: null,
                        universe: null,
                    };

                    return widget;
                }
                case 'simple-order-entry': {
                    const widget: SimpleOrderEntryWidgetModel = {
                        type: 'simple-order-entry',
                        channelId,
                        accountId: null,
                        skipTradeConfirmation: false,
                        symbol: null,
                    };

                    return widget;
                }
                case 'order-entry': {
                    const widget: OrderEntryWidgetModel = {
                        type: 'order-entry',
                        channelId,
                        accountId: null,
                        skipTradeConfirmation: false,
                        symbol: null,
                    };

                    return widget;
                }
                case 'messages': {
                    const widget: MessagesWidgetViewModel = {
                        type: 'messages',
                        channelId,
                        accountId: null,
                        statusFilter: null,
                        viewData: null,
                        formattingSettings: null,
                        symbolFilter: '',
                    };

                    return widget;
                }
                case 'positions': {
                    const widget: PositionsWidgetViewModel = {
                        type: 'positions',
                        channelId,
                        columnTemplate: null,
                        platforms: null,
                        accountIds: null,
                        userIds: null,
                        filter: null,
                        symbolFilter: '',
                    };

                    return widget;
                }

                case 'orders': {
                    const widget: OrdersWidgetViewModel = {
                        type: 'orders',
                        channelId,
                        columnTemplate: null,
                        platforms: null,
                        accountIds: null,
                        userIds: null,
                        filter: null,
                        symbolFilter: '',
                    };

                    return widget;
                }

                case 'table': {
                    const widget: TableWidgetModel = {
                        type: 'table',
                        channelId,
                        table: null,
                        class: null,
                        filter: null,
                        viewData: null,
                    };
                    return widget;
                }
                case 'executions': {
                    const widget: ExecutionsWidgetViewModel = {
                        type: 'executions',
                        channelId,
                        columnTemplate: null,
                        platforms: null,
                        accountIds: null,
                        userIds: null,
                        filter: null,
                        symbolFilter: '',
                    };

                    return widget;
                }

                case 'locates': {
                    const widget: LocatesWidgetModel = {
                        type: 'locates',
                        channelId,
                        accountIds: null,
                        filter: null,
                        isQuantityKilo: false,
                        isSoundAlertEnabled: false,
                        selectedTab: 'all',
                        isUserOnly: true,
                        formData: {
                            accountId: null,
                            symbol: '',
                            quantity: defaultQuantityStep,
                            providerId: null,
                        },
                        ordersTableData: {
                            viewData: null,
                            formattingSettings: null,
                        },
                        costInfoTableData: {
                            columnTemplate: null,
                        },
                        quickSendButtons: [],
                    };

                    return widget;
                }
                default:
                    throw new UnreachableCaseError(widgetType);
            }
        }
        default:
            throw new UnreachableCaseError(creationInfo);
    }
}
