import type { BespokeIndicatorImport, IndicatorFormula } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { type Idea } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type { FilterStage } from '@thinkalpha/platform-ws-client/contracts/table.js';
import { type ThinkAlphaQueryClient } from 'src/lib/config/query-client';

export interface IdeaConstructionOptions<TPlan extends IdeaPlanDTO = IdeaPlanDTO> {
    name: string | null;
    plan: TPlan;
}

/**
 * Idea DTO
 *
 * This abstracts over the Idea type
 */
export class IdeaDTO<TPlan extends IdeaPlanDTO = IdeaPlanDTO> {
    constructor({ name, plan }: IdeaConstructionOptions<TPlan>) {
        this.#name = name;
        this.#plan = plan;
    }

    get name(): string | null {
        return this.#name;
    }

    get plan() {
        return this.#plan;
    }

    static fromIdeaAndPlan(ideaContract: Idea, planDto: IdeaPlanDTO): IdeaDTO {
        return new IdeaDTO({
            name: ideaContract.name,
            plan: planDto,
        });
    }

    async toFilterStage(queryClient: ThinkAlphaQueryClient): Promise<Omit<FilterStage, 'inputStage'> | null> {
        const result = await this.#plan.renderToFormula(queryClient);

        if (!result) return null;

        const { formula, imports } = result;

        return {
            type: 'filter',
            formula,
            imports,
        };
    }

    async toValidationModeFilterStageAndOutputs(
        queryClient: ThinkAlphaQueryClient,
    ): Promise<[Omit<FilterStage, 'inputStage'>, BespokeIndicatorImport[]] | null> {
        const results = await this.#plan.getValidationModeFormulaAndIndicators(queryClient);

        if (!results) return null;

        const [bespokeIndicators, { formula, imports }] = results;

        const filterStage: Omit<FilterStage, 'inputStage'> = {
            type: 'filter',
            formula,
            imports,
        };

        return [filterStage, bespokeIndicators];
    }

    #name: string | null;

    #plan: TPlan;
}

export abstract class IdeaPlanDTO {
    abstract renderToFormula(queryClient: ThinkAlphaQueryClient): Promise<IndicatorFormula | null>;
    abstract getValidationModeFormulaAndIndicators(
        queryClient: ThinkAlphaQueryClient,
    ): Promise<[BespokeIndicatorImport[], IndicatorFormula] | null>;
}
