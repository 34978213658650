import { Instant } from '@js-joda/core';
import { doesAccessGrantAllowRequest } from '@thinkalpha/common/util/permissions.js';
import { ConcreteIdea, Idea, NewIdea, ScreenerPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { SCRANNER_CHANGE_EVENT } from 'src/features/scranner/dtos/ScrannerPlanDTO';
import { ScreenerPlanDTO } from 'src/features/screener/dtos/ScreenerPlanDTO';
import { getScreenerRowCount } from 'src/features/screener/lib/getScreenerRowCount';
import { ScreenerWidgetModel } from 'src/models/ScreenerWidgetModel';
// TODO make a mutation in src/queries/ideas to avoid this
// eslint-disable-next-line no-restricted-imports
import { createIdea } from 'src/queries/http/ideas';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import {
    setScreenerDisplayingBuilder,
    setScreenerDisplayingResults,
    setScreenerIdea,
    storeScreenerSplitPercentage,
} from 'src/store/actions/widgets/screener';
import type { ScreenerWidgetViewModel } from 'src/store/types';
import { ReactBindings } from 'src/types/bindings';

@injectable()
export class ScreenerWidgetModelImpl extends ReactiveInjectable implements ScreenerWidgetModel {
    #name: string;
    #description: string;

    get canSaveIdea() {
        if (!this.#plan.isDirty) {
            return false;
        }

        if ('id' in this.idea && 'permissions' in this.idea) {
            return doesAccessGrantAllowRequest(
                (this.idea as ConcreteIdea).permissions.effectiveDirectAccess || undefined,
                'writer',
            );
        }

        return false;
    }

    get columnTemplate() {
        return this.widget.columnTemplate;
    }

    createNewScreener(): void {
        this.lastSubmit = Instant.now();
        this.#plan = new ScreenerPlanDTO(undefined, this.queryClient, this.formulaService);
        this.#name = 'New Screener';
        this.#description = '';

        this.useIdea({
            name: this.#name,
            description: this.#description,
            plan: this.#plan.toContract(),
            defaultUniverseId: null,
            defaultColumnTemplateId: null,
            isTemplate: false,
        });

        this.#initNewScreener();
    }

    constructor(
        @inject('ImportsManagerModel') @reacts public importsManager: ReactBindings['ImportsManagerModel'],
        @inject('Store') @reacts private store: ReactBindings['Store'],
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('ResultsAreaModel') public resultsAreaModel: ReactBindings['ResultsAreaModel'],
        @inject('QueryClient') private queryClient: ReactBindings['QueryClient'],
        @inject('FormulaService') private formulaService: ReactBindings['FormulaService'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
        this.#plan = new ScreenerPlanDTO(undefined, queryClient, formulaService);
        this.#name = 'New Screener';
        this.#description = '';
    }

    async init(tabId: string) {
        this.widgetData.init(tabId);

        // if we have an idea, set up the plan from it
        if (this.idea) {
            this.#name = this.idea.name ?? '';
            this.#description = this.idea.description ?? '';

            if (this.idea.plan.type === 'screener') {
                this.#plan = await ScreenerPlanDTO.fromPlan(this.idea.plan, this.queryClient, this.formulaService);
            }
        }

        this.#initNewScreener();
    }

    #initNewScreener() {
        const listener = this.#onScreenerChange.bind(this);
        this.#plan.changeTarget.addEventListener(SCRANNER_CHANGE_EVENT, listener);
        this.disposableStack.defer(() => {
            this.#plan.changeTarget.removeEventListener(SCRANNER_CHANGE_EVENT, listener);
        });
        this.#plan.changeTarget.dispatchEvent(new Event(SCRANNER_CHANGE_EVENT));

        {
            // Fetch the total possible row count, and avoid updating this class if we're disposed
            const rowTotalAbortController = new AbortController();
            this.disposableStack.defer(() => {
                rowTotalAbortController.abort();
            });

            void getScreenerRowCount(this.queryClient, rowTotalAbortController.signal).then((rowCount) => {
                if (rowTotalAbortController.signal.aborted) {
                    return;
                }
                this.rowCountTotal = rowCount;
            });
        }
    }

    get isShowingBuilder() {
        return this.widget.displayingForm;
    }

    set isShowingBuilder(value: boolean) {
        this.store.dispatch(setScreenerDisplayingBuilder(this.widgetData.tabId, value));
    }

    get isShowingResults() {
        return this.widget.displayingResults;
    }

    set isShowingResults(value: boolean) {
        this.store.dispatch(setScreenerDisplayingResults(this.widgetData.tabId, value));
    }

    #lastSubmit = Instant.now();
    get lastSubmit() {
        return this.#lastSubmit;
    }

    @reacts set lastSubmit(value: Instant) {
        this.#lastSubmit = value;
    }

    #rowCountFiltered = 0;
    get rowCountFiltered() {
        return this.#rowCountFiltered;
    }

    @reacts set rowCountFiltered(value: number) {
        this.#rowCountFiltered = value;
    }

    #rowCountFilteredLoading = true;
    get rowCountFilteredLoading() {
        return this.#rowCountFilteredLoading;
    }

    @reacts set rowCountFilteredLoading(value: boolean) {
        this.#rowCountFilteredLoading = value;
    }

    #rowCountTotal = 0;
    get rowCountTotal() {
        return this.#rowCountTotal;
    }

    @reacts set rowCountTotal(value: number) {
        this.#rowCountTotal = value;
    }

    #rowCountFilteredAbortController: AbortController | null = null;

    #onScreenerChange() {
        if (this.#rowCountFilteredAbortController) {
            this.#rowCountFilteredAbortController.abort();
        }

        this.#rowCountFilteredAbortController = new AbortController();
        const signal = this.#rowCountFilteredAbortController.signal;
        this.rowCountFilteredLoading = true;
        void getScreenerRowCount(this.queryClient, this.#rowCountFilteredAbortController.signal, this.#plan).then(
            (count) => {
                if (signal.aborted) return;
                this.rowCountFiltered = count;
                this.rowCountFilteredLoading = false;
            },
        );

        this.rerender();
    }

    async saveIdea(): Promise<ConcreteIdea> {
        const ideaToSave = this.toIdea();

        if (!ideaToSave.id) {
            throw new Error('Cannot save idea without an id');
        }

        const newIdea = (await createIdea(ideaToSave)) as ConcreteIdea & {
            plan: ScreenerPlan;
        };

        this.useIdea(newIdea);

        return newIdea;
    }

    async saveIdeaAs(name: string): Promise<ConcreteIdea> {
        const ideaToSave = this.toIdea(name);

        if (!ideaToSave.name) {
            throw new Error('Cannot save idea without a name');
        }

        const newIdea = (await createIdea(ideaToSave)) as ConcreteIdea & {
            plan: ScreenerPlan;
        };

        this.useIdea(newIdea);

        return newIdea;
    }

    toIdea(name?: string): NewIdea {
        return {
            ...this.idea,
            name: name ?? this.#name,
            description: this.#description,
            plan: this.#plan.toContract(),
        };
    }

    #plan: ScreenerPlanDTO;

    get screener() {
        return this.#plan;
    }

    get splitPercentage() {
        return this.widget.splitPercentage;
    }

    set splitPercentage(value: number) {
        this.store.dispatch(storeScreenerSplitPercentage(this.widgetData.tabId, value));
    }

    submit() {
        this.lastSubmit = Instant.now();
    }

    userSelectedSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    get idea() {
        return this.widget.idea;
    }

    async useIdea(idea: Idea & { plan: ScreenerPlan }) {
        this.store.dispatch(setScreenerIdea(this.widgetData.tabId, idea));

        this.#name = idea.name ?? '';
        this.#description = idea.description ?? '';

        if (idea.plan.type === 'screener') {
            this.#plan = await ScreenerPlanDTO.fromPlan(idea.plan, this.queryClient, this.formulaService);
            this.#initNewScreener();
        }
    }

    get widget() {
        return this.widgetData.widget as ScreenerWidgetViewModel;
    }
}
